import styles from "components/blog/card/BlogCard.module.scss";
import { get } from "lodash";

import dynamic from "next/dynamic";
const Link = dynamic(() => import("next/link"));
const Image = dynamic(() =>
  import("grandus-lib/components-atomic/image/Image")
);

const BlogCard = ({ title, urlTitle, perex, photo, publishTime, communityCategory }) => {
  return (
      <article
        className={[styles.blogPostBox, "blogPostBox"].join(" ")}
      >
        <Link href="/blog/[id]" as={`/blog/${urlTitle}`}>
          <a>
            {get(communityCategory, "name") ? (
              <span className={styles.blogPostBadge}>
                {get(communityCategory, "name")}
              </span>
            ) : (
              ""
            )}
            <div className={styles.blogPostThumb}>
              <Image photo={photo} size={"460x266__cropped"} type={"jpg"} className={styles.image} />
              <img src="img/arrow-right.svg" className="svg" className={styles.image} />
            </div>
            <div className={styles.text}>
              <div className={styles.postTitle}>
                <h4>{title}</h4>
                <div className={styles.meta}>
                  {/* <div className="comments">
                                <img src="img/icon-comments.svg" className="svg" />
                                <span>2</span>
                            </div> */}
                  <time dateTime={publishTime}>
                    {publishTime}
                  </time>
                </div>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: `<p>${perex}</p>`,
                }}
              />
            </div>
          </a>
        </Link>
      </article>
  );
};

export default BlogCard;
