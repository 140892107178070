import styles from "pages/index/index.page.module.scss";
import { indexPage } from "grandus-lib/utils/fetches";
import EnhancedEcommerce from "utils/ecommerce";
import Remarketing from "grandus-lib/utils/remarketing";
import TagManager from "grandus-lib/utils/gtag";
import Countdown from "react-countdown";

import {
  reqGetHeaders,
  reqGetHost,
  reqApiHost,
  getProductCardFields,
} from "grandus-lib/utils";

import { get, map, isEmpty, find } from "lodash";
import Link from "next/link";
import { getImageUrl } from "grandus-lib/utils/index";
import { getCategoryLinkAttributes } from "grandus-lib/hooks/useFilter";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import MegaMenu from "components/menu/MegaMenu";
import MetaData from "grandus-lib/components-atomic/MetaData";
import Content from "grandus-lib/components-atomic/content/Content";
import ProductCard from "components/product/card/ProductCard";
import BlogCard from "components/blog/card/BlogCard";
import Testimonials from "components/testimonials/Testimonials";
import Image from "grandus-lib/components-atomic/image/Image";

import { COUNTDOWN_PARAM_HASH } from "constants/ProductConstants";

const Counter = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <h5>Skvelá šanca na dobrú kúpu 🎉</h5>;
  } else {
    // Render a countdown
    return (
      <>
        <h5>Ponuka končí už o...</h5>
        <div className={styles.countdown}>
          <div className={styles.days}>
            <span id="jsDay">{days}</span>
            <span>dni</span>
          </div>
          <div className={styles.hours}>
            <span>{hours}</span>
            <span>hod</span>
          </div>
          <div className={styles.minutes}>
            <span>{minutes}</span>
            <span>min</span>
          </div>
          <div className={styles.seconds}>
            <span>{seconds}</span>
            <span>sek</span>
          </div>
        </div>
      </>
    );
  }
};

const HomepageContent = ({ pages }) => {
  if (!pages) return null;

  return (
    <>
      {pages.map((page, index) => {
        const { id, content, customJavascript, customCss } = page;
        return (
          <Content
            content={content}
            css={customCss}
            js={customJavascript}
            key={`static-page-${id}-${index}`}
          />
        );
      })}
    </>
  );
};

const Homepage = (props) => {
  const {
    categories = [],
    meta = {},
    banners = [],
    banners01 = [],
    banners02 = [],
    products = [],
    productsNew = [],
    productsLatest = [],
    productsCampaign = [],
    blogs = [],
    homepageStaticPageLocation1 = [],
    homepageStaticPageLocation2 = [],
    homepageStaticPageLocation3 = [],
    homepageStaticPageLocation4 = [],
    homepageStaticPageLocation5 = [],
  } = props;

  const SliderArrowsGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;

    return (
      <div className="carouselArrows">
        <button className="arrowPrev" onClick={() => previous()}>
          <img src="img/arrow-left.svg" />
        </button>
        <button className="arrowNext" onClick={() => next()}>
          <img src="img/arrow-right.svg" />
        </button>
      </div>
    );
  };

  const logosSliderResponsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 8,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 8,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 6,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1,
    },
  };

  const promoSliderResponsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const miniBannersSliderResponsive = {
    tablet: {
      breakpoint: { max: 1024, min: 576 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 2,
      slidesToSlide: 1,
    },
  };

  const productSliderResponsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
      slidesToSlide: 1,
    },
    largeDesktop: {
      breakpoint: { max: 3000, min: 1600 },
      items: 5,
      slidesToSlide: 1,
    },
    desktop1: {
      breakpoint: { max: 1600, min: 1400 },
      items: 4,
      slidesToSlide: 1,
    },
    desktop2: {
      breakpoint: { max: 1400, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 576 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 2,
      slidesToSlide: 1,
      // partialVisibilityGutter: -13
    },
  };

  const blogSliderResponsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
      slidesToSlide: 1,
    },
    largeDesktop: {
      breakpoint: { max: 3000, min: 1600 },
      items: 3,
      slidesToSlide: 1,
    },
    desktop1: {
      breakpoint: { max: 1600, min: 1024 },
      items: 4,
      slidesToSlide: 1,
    },
    desktop2: {
      breakpoint: { max: 1400, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 576 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 2,
      slidesToSlide: 1,
      // partialVisibilityGutter: 140
    },
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 6,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
        },
      },
    ],
  };

  React.useEffect(() => {
    TagManager.push(
      EnhancedEcommerce.impressions(products, "homepage favourite products")
    );
    TagManager.push(
      EnhancedEcommerce.impressions(productsNew, "homepage new products")
    );
    TagManager.push(Remarketing.viewItemList(products));
    TagManager.push(Remarketing.viewItemList(productsNew));

    if (typeof window !== "undefined") {
      let getStyle = function (e, styleName) {
        let styleValue = "";

        if (document.defaultView && document.defaultView.getComputedStyle) {
          styleValue = document.defaultView
            .getComputedStyle(e, "")
            .getPropertyValue(styleName);
        } else if (e.currentStyle) {
          styleName = styleName.replace(/\-(\w)/g, function (strMatch, p1) {
            return p1.toUpperCase();
          });
          styleValue = e.currentStyle[styleName];
        }
        return styleValue;
      };

      let sliders = document.querySelectorAll(".productCarousel");
      let miniBannerSlider = document.querySelector(".miniBannerCarousel");
      let container = document.getElementById("container");

      function recalculateSliderContainer() {
        setTimeout(() => {
          let marginLeft = getStyle(container, "margin-left").replace("px", "");

          if (miniBannerSlider !== null)
            miniBannerSlider.style.marginLeft =
              parseInt(marginLeft) + 15 + "px";
          // miniBannerSlider.firstChild.style.marginLeft = parseInt(marginLeft) + 15 + 'px';

          marginLeft = parseInt(marginLeft) + 15 + "px";

          sliders.forEach((item) => {
            item.style.marginLeft = marginLeft;
            item.style.width = "calc(100% - " + marginLeft + ")";

            // item.firstChild.style.marginLeft = parseInt(marginLeft) + 15 + 'px';
            item.firstChild.style.width = "100%";
          });
        }, 500);
      }

      if (typeof container != "undefined" && container != null) {
        recalculateSliderContainer();

        window.addEventListener("resize", function () {
          recalculateSliderContainer();
        });
      }
    }
  }, []);

  meta.options = {
    image: {
      dimensions: "1200",
      width: 1200,
      height: -1,
    },
  };
  meta.photo = get(banners, "[0].photo", {});
  return (
    <>
      <MetaData {...meta} />
      <main id="homepage">
        <aside
          className={[styles.categoryWidgetFixed, "categoryWidgetFixed"].join(
            " "
          )}
          id="categoryWidgetFixed"
        >
          <ul>
            {map(categories, (category) => {
              return (
                <li
                  className={[
                    category?.urlName.indexOf("vypredaj") >= 0
                      ? styles.discount
                      : "",
                  ].join(" ")}
                  key={`category-home-01-${category?.id}`}
                >
                  <Link
                    {...getCategoryLinkAttributes(category?.urlName)}
                    scroll={true}
                  >
                    <a>
                      <Image
                        photo={get(category, "mainPhoto", {})}
                        size={"45x45"}
                        type={"png"}
                      />
                      <div className={[styles.text, "jsText"].join(" ")}>
                        <span>{category?.name}</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13.939"
                          height="9.547"
                          viewBox="0 0 13.939 9.547"
                        >
                          <path
                            d="M8.577,9.353h0a.661.661,0,0,0,.936,0L13.745,5.48a.662.662,0,0,0,0-.936L9.513.194A.662.662,0,1,0,8.6,1.15l3.089,3.192H.662A.662.662,0,0,0,0,5v.029a.661.661,0,0,0,.662.661H11.674L8.6,8.4a.661.661,0,0,0-.021.955"
                            transform="translate(0 0)"
                          />
                        </svg>
                      </div>
                    </a>
                  </Link>
                </li>
              );
            })}
          </ul>
        </aside>
        {/* End Fixed Category List */}

        {/* Start Section Promo */}
        <section className={styles.promo}>
          <div className="custom-container" id="container">
            {/* Start Grid Section */}
            <div className={styles.grid}>
              <aside className={styles.categoryWidget}>
                <ul className={styles.categoryWidgetList}>
                  {map(categories, (category) => {
                    return (
                      <li
                        key={`category-homepage-main-level0-${category?.id}`}
                        className={[
                          !isEmpty(get(category, "children", []))
                            ? styles.hasMegamenu
                            : "",
                          styles.categoryWidgetItem,
                          category?.urlName.indexOf("vypredaj") >= 0
                            ? styles.discount
                            : "",
                        ].join(" ")}
                      >
                        <Link
                          {...getCategoryLinkAttributes(category?.urlName)}
                          scroll={true}
                        >
                          <a className={styles.mainCategoryLink}>
                            <Image
                              photo={get(category, "mainPhoto", {})}
                              size={"45x45"}
                              type={"png"}
                            />
                            {category?.name}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13.939"
                              height="9.547"
                              viewBox="0 0 13.939 9.547"
                            >
                              <path
                                d="M8.577,9.353h0a.661.661,0,0,0,.936,0L13.745,5.48a.662.662,0,0,0,0-.936L9.513.194A.662.662,0,1,0,8.6,1.15l3.089,3.192H.662A.662.662,0,0,0,0,5v.029a.661.661,0,0,0,.662.661H11.674L8.6,8.4a.661.661,0,0,0-.021.955"
                                transform="translate(0 0)"
                              />
                            </svg>
                          </a>
                        </Link>
                        {!isEmpty(get(category, "children", [])) ? (
                          <MegaMenu
                            subCategories={get(category, "children", [])}
                            styles={styles}
                          />
                        ) : (
                          ""
                        )}
                      </li>
                    );
                  })}
                </ul>
              </aside>
              <div className={[styles.promoSlider, "promoSlider"].join(" ")}>
                <Carousel
                  responsive={promoSliderResponsive}
                  swipeable={true}
                  draggable={false}
                  infinite={true}
                  ssr={true}
                  arrows={false}
                  customButtonGroup={<SliderArrowsGroup />}
                  containerClass="promoCarousel"
                  autoPlay
                  autoPlaySpeed={3000}
                >
                  {map(banners, (banner, index) => {
                    const { id, url, photo } = banner;
                    return (
                      <div
                        key={`banners-main-${id}-${index}`}
                        className={styles.slide}
                      >
                        {url ? (
                          <Link href={url}>
                            <a>
                              <Image
                                photo={photo}
                                size={"866x448__cropped"}
                                type={"jpg"}
                                className={styles.slideImage}
                              />
                              {/* <div className="button secondary">
                                Zobraziť viac
                                <img src="img/arrow-right-1.svg" />
                              </div> */}
                            </a>
                          </Link>
                        ) : (
                          <Image
                            photo={photo}
                            size={"866x448__cropped"}
                            type={"jpg"}
                            className={styles.slideImage}
                          />
                        )}
                      </div>
                    );
                  })}
                </Carousel>
              </div>
              <div className={styles.adBanner}>
                {get(banners01, "[0].photo") ? (
                  <>
                    {get(banners01, "[0].url") ? (
                      <Link href={get(banners01, "[0].url")}>
                        <a>
                          <Image
                            photo={get(banners01, "[0].photo")}
                            size={"283x448__cropped"}
                            type={"jpg"}
                          />
                          {/* <div className={styles.text}>
                            <div className="button secondary">
                              Zobraziť viac
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13.939"
                                height="9.547"
                                viewBox="0 0 13.939 9.547"
                              >
                                <path
                                  d="M8.577,9.353h0a.661.661,0,0,0,.936,0L13.745,5.48a.662.662,0,0,0,0-.936L9.513.194A.662.662,0,1,0,8.6,1.15l3.089,3.192H.662A.662.662,0,0,0,0,5v.029a.661.661,0,0,0,.662.661H11.674L8.6,8.4a.661.661,0,0,0-.021.955"
                                  transform="translate(0 0)"
                                />
                              </svg>
                            </div>
                          </div> */}
                        </a>
                      </Link>
                    ) : (
                      <Image
                        photo={get(banners01, "[0].photo")}
                        size={"283x448__cropped"}
                        type={"jpg"}
                      />
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            {/* End Grid Section */}

            {/* Start Mini Banners Section Desktop */}
            {!isEmpty(banners02) ? (
              <section className={styles.miniBanners}>
                <div className={styles.gridMiniBanners}>
                  {map(banners02, (banner, index) => {
                    const { id, url, photo } = banner;
                    return (
                      <div
                        key={`banners-mini-desktop-${id}-${index}`}
                        className={styles.miniBanner}
                      >
                        <Link href={url ? url : "/"} scroll={true}>
                          <a>
                            <Image
                              photo={photo}
                              size={"319x150__cropped"}
                              type={"jpg"}
                            />
                            <img
                              className={styles.svg}
                              src="img/arrow-right.svg"
                            />
                          </a>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </section>
            ) : (
              ""
            )}

            {/* End Mini Banners Section Desktop */}
          </div>
        </section>
        {/* End Section Promo */}

        {/* Start Mini Banners Section Mobile */}
        {!isEmpty(banners02) ? (
          <section
            className={[styles.miniBanners, styles.miniBannersMobile].join(" ")}
          >
            <Carousel
              responsive={miniBannersSliderResponsive}
              swipeable={true}
              draggable={false}
              infinite={false}
              ssr={true}
              arrows={false}
              showDots={false}
              partialVisible={false}
              containerClass="miniBannerCarousel"
            >
              {map(banners02, (banner, index) => {
                const { id, url, photo } = banner;
                return (
                  <div
                    key={`banners-mini-mobile-${id}-${index}`}
                    className={styles.miniBanner}
                  >
                    <Link href={url ? url : "/"} scroll={true}>
                      <a>
                        <Image
                          photo={photo}
                          size={"319x150__cropped"}
                          type={"jpg"}
                        />
                        <img className={styles.svg} src="img/arrow-right.svg" />
                      </a>
                    </Link>
                  </div>
                );
              })}
            </Carousel>
          </section>
        ) : (
          ""
        )}
        {/* End Mini Banners Section Mobile */}

        {/* Start Favourite Products Slider */}
        <section
          className={[
            styles.productSliderWrapper,
            "productSliderWrapper",
            "favouriteProducts",
            "productSlider",
          ].join(" ")}
        >
          <div className="custom-container">
            <div className="sliderTitle">
              <h2>Obľúbené produkty</h2>
              {/* <a href="">Zobraziť viac</a> */}
            </div>
          </div>
          <Carousel
            responsive={productSliderResponsive}
            swipeable={true}
            draggable={false}
            infinite={false}
            ssr={true}
            arrows={false}
            showDots={true}
            partialVisible={true}
            customButtonGroup={<SliderArrowsGroup />}
            containerClass="productCarousel"
          >
            {map(products, (product) => {
              return (
                <div key={`carousel-products-favourite01-${product?.id}`}>
                  <ProductCard {...product} />
                </div>
              );
            })}
          </Carousel>
        </section>
        {/* End Favourite Products Slider */}

        {/* Start Benefit Products Slider */}
        <section
          className={[
            styles.productSliderWrapper,
            "benefitProducts",
            "productSlider",
          ].join(" ")}
        >
          <div className="custom-container">
            <div className="sliderTitle">
              <h2>Najvýhodnejšie</h2>
              {/* <a href="">Zobraziť viac</a> */}
            </div>
          </div>
          <Carousel
            responsive={productSliderResponsive}
            swipeable={true}
            draggable={false}
            infinite={false}
            ssr={true}
            arrows={false}
            showDots={true}
            partialVisible={true}
            customButtonGroup={<SliderArrowsGroup />}
            containerClass="productCarousel"
          >
            {map(productsNew, (product) => {
              const options = { style: { wrapperClasses: ["benefitBox"] } };

              return (
                <div key={`carousel-products-favourite-${product?.id}`}>
                  <ProductCard {...product} options={options} />
                </div>
              );
            })}
          </Carousel>
        </section>
        {/* End Benefit Products Slider */}

        {/* Start Ads Banners Section */}
        {!isEmpty(productsCampaign) ? (
          <section className={styles.bannerOffer}>
            <div className="custom-container">
              <div className={styles.sectionTitle}>
                <img src="img/avatar-3d.png" className="svg" />
                <h2>Špeciálna ponuka!</h2>
              </div>

              {map(productsCampaign, (product, index) => {
                const countdownParam = find(get(product, "parameters"), [
                  "hash",
                  COUNTDOWN_PARAM_HASH,
                ]);
                return (
                  <Link
                    href={`/produkt/${product?.urlTitle}`}
                    scroll={true}
                    key={`campaigne-product-${product?.id}-${index}`}
                  >
                    <div className={styles.bannerWrapper}>
                      <div className={styles.bannerOfferImageWrapper}>
                        <div className={styles.image}>
                          <img
                            src={getImageUrl(product?.photo, "600x400", "png")}
                            alt={product?.name}
                          />
                          {product?.discount ? (
                            <div
                              className={[
                                styles.discountBadge,
                                "discountBadge",
                              ].join(" ")}
                            >
                              <span>
                                Ušetri až <br />
                                <strong>-{product?.discount}</strong>
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className={styles.first}>
                        <h3>{product?.name}</h3>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: product?.shortDescription,
                          }}
                        />
                        <p className={styles.textAlert}>
                          {product?.storeStatus?.message}
                        </p>
                        <div className={styles.prices}>
                          {product?.standardPriceData?.price >
                          product?.finalPriceData?.price ? (
                            <div className={styles.pricesMain}>
                              <span>Pôvodná cena:</span>
                              <span>
                                {product?.standardPriceData?.priceFormatted}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}

                          <div className={styles.pricesDiscount}>
                            <span>Zvýhodnená cena:</span>
                            <span>
                              {product?.finalPriceData?.priceFormatted}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className={styles.second}>
                        <div className={styles.wrapper}>
                          <Countdown
                            autoStart={true}
                            // date={Date.now() + 50000}
                            date={get(
                              countdownParam,
                              "value",
                              "2021-01-01 00:00:00"
                            )} // default value to display message "Skvelá šanca na dobrú kúpu"
                            renderer={Counter}
                          />
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          </section>
        ) : (
          ""
        )}
        {/* End Ads Banners Section */}

        {/* Start Banner Links Section */}
        <section className={styles.bannerLinks}>
          <div className="custom-container">
            <div className={styles.bannerLinksGrid}>
              <div className={styles.box}>
                <h3>
                  Vyberte si podľa vašej činnosti, kde budete zariadenie
                  používať.
                </h3>
              </div>
              <div className={styles.box}>
                <Link href={"/kategoria/pre-firmy"}>
                  <a>
                    <img src="img/thumbnail.png" alt="" />
                    <div className={styles.bannerText}>
                      <h5>Pre firmy</h5>
                      <div>
                        <span className="button secondary">
                          Zobraziť viac
                          <img src="img/arrow-right.svg" />
                        </span>
                      </div>
                    </div>
                  </a>
                </Link>
              </div>
              <div className={styles.box}>
                <Link href={"/kategoria/pre-domacnosti"}>
                  <a>
                    <img src="img/thumbnail-1.png" alt="" />
                    <div className={styles.bannerText}>
                      <h5>Do domácnosti</h5>
                      <div>
                        <span className="button secondary">
                          Zobraziť viac
                          <img src="img/arrow-right.svg" />
                        </span>
                      </div>
                    </div>
                  </a>
                </Link>
              </div>
            </div>
            <div className={styles.bannerLinksGrid}>
              <div className={styles.box}>
                <Link href={"/kategoria/pre-hracov"}>
                  <a>
                    <img src="img/thumbnail-3.png" alt="" />
                    <div className={styles.bannerText}>
                      <h5>Pre hráčov</h5>
                      <div>
                        <span className="button secondary">
                          Zobraziť viac
                          <img src="img/arrow-right.svg" />
                        </span>
                      </div>
                    </div>
                  </a>
                </Link>
              </div>
              <div className={styles.box}>
                <Link href={"/kategoria/pre-studentov"}>
                  <a>
                    <img src="img/thumbnail-2.png" alt="" />
                    <div className={styles.bannerText}>
                      <h5>Pre študentov</h5>
                      <div>
                        <span className="button secondary">
                          Zobraziť viac
                          <img src="img/arrow-right.svg" />
                        </span>
                      </div>
                    </div>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </section>
        {/* End Banner Links Section */}

        {/* Start News Products Slider */}
        <section
          className={[
            styles.productSliderWrapper,
            "newsProducts",
            "productSlider",
          ].join(" ")}
        >
          <div className="custom-container">
            <div className="sliderTitle">
              <h2>Novinky</h2>
              {/* <a href="">Zobraziť viac</a> */}
            </div>
          </div>
          <Carousel
            responsive={productSliderResponsive}
            swipeable={true}
            draggable={false}
            infinite={false}
            ssr={true}
            arrows={false}
            showDots={true}
            partialVisible={true}
            customButtonGroup={<SliderArrowsGroup />}
            containerClass="productCarousel"
          >
            {map(productsLatest, (product) => {
              return (
                <div key={`carousel-products-latest-${product?.id}`}>
                  <ProductCard {...product} />
                </div>
              );
            })}
          </Carousel>
        </section>
        {/* End News Products Slider */}

        {/* Start Blog Posts Slider */}
        <section
          className={[
            styles.productSliderWrapper,
            "blogPosts",
            "productSlider",
          ].join(" ")}
        >
          <div className="custom-container">
            <div className="sliderTitle">
              <h2>
                Prečítajte si <br /> náš blog.
              </h2>
              <a href="" className="imageTitle">
                <img src="img/logo-bigblog.png" alt="" />
              </a>
            </div>
          </div>
          <Carousel
            responsive={blogSliderResponsive}
            swipeable={true}
            draggable={false}
            infinite={false}
            ssr={true}
            arrows={false}
            showDots={true}
            partialVisible={true}
            customButtonGroup={<SliderArrowsGroup />}
            containerClass="productCarousel"
          >
            {blogs.map((blog, index) => {
              return (
                <div key={`banners-${blog.id}-${index}`}>
                  <BlogCard {...blog} />
                </div>
              );
            })}
          </Carousel>
        </section>
        {/* End Blog Posts Slider */}

        {/* Start Testimonials Section */}
        <section className={styles?.testimonialsSection}>
          <Testimonials />
        </section>
        {/* End Testimonials Section */}
      </main>

      {/* <section className={styles.wrapper}> */}
      {/* STATIC PAGES LOCATION 01 */}
      {/* <HomepageContent pages={homepageStaticPageLocation1} /> */}

      {/* STATIC PAGES LOCATION 02 */}
      {/* <HomepageContent pages={homepageStaticPageLocation2} /> */}

      {/* STATIC PAGES LOCATION 03 */}
      {/* <HomepageContent pages={homepageStaticPageLocation3} /> */}

      {/* STATIC PAGES LOCATION 04 */}
      {/* <HomepageContent pages={homepageStaticPageLocation4} /> */}

      {/* STATIC PAGES LOCATION 05 */}
      {/* <HomepageContent pages={homepageStaticPageLocation5} /> */}
      {/* </section> */}
    </>
  );
};

export async function getServerSideProps(context) {
  const data = await indexPage.serverSideProps(context);
  const categories = await fetch(
    `${reqGetHost(context.req)}/api/lib/v1/categories`
  ).then((result) => result.json());

  data.props.categories = categories;
  return data;
}

export default Homepage;
