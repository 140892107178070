import { Carousel } from "antd";
import { filter, isEmpty, map, startsWith } from "lodash";
import useSWR from "swr";

import styles from "components/testimonials/Testimonials.module.scss";

const settings = {
  dots: false,
  arrows: false,
  infinite: true,
  autoplaySpeed: 5000,
  slidesToScroll: 1,
  slidesToShow: 1,
};

const Testimonials = () => {
  const { data: staticBlocks } = useSWR(
    `/api/lib/v1/blocks`,
    (url) => fetch(url).then((r) => r.json()),
    {
      revalidateOnReconnect: false,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );

  const testimonials = filter(
    staticBlocks,
    (block) => startsWith(block?.hash, "reference_") && !isEmpty(block?.content)
  );

  if (isEmpty(testimonials)) {
    return null;
  }

  return (
    <section
      className={`${styles?.testimonials} testimonials testimonialsCarousel`}
    >
      <div className="custom-container">
        <div className={`${styles?.testimonialsWrapper} testimonialsWrapper`}>
          <Carousel {...settings} autoplay pauseOnHover>
            {map(testimonials, (testimonial) => (
              <div
                key={testimonial?.hash}
                className={`${styles?.testimonial} testimonial`}
              >
                <div
                  className={styles?.description}
                  dangerouslySetInnerHTML={{ __html: testimonial?.content }}
                />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
